<template>
  <div class="zem-collapse-table rows">
    <div v-for="(item, index) in product['fields']" :key="'a' + index" class="zem-collapse-table__row">
      <div :class="{'required-field': index > 1}" class="zem-collapse-table__column title">
        {{ item.title }}
      </div>
      <div v-if="!editProduct && !newProduct" class="zem-collapse-table__column text">
        {{ values(item) }}
      </div>
      <zem-input
        v-else-if="item.type === 'input' || item.type === 'numeric'"
        v-model="item.data"
        :disabled="!$can('edit', 'directory-item-edit-dynamic_properties')"
        :type="item.type === 'numeric' ? 'number' : 'text'"
        class="mt-0"
        :is-min-zero="item.type === 'numeric'"
      ></zem-input>
      <ZemDropdownList
        v-else-if="item.type === 'list'"
        v-model="item.data"
        :disabled="!$can('edit', 'directory-item-edit-dynamic_properties')"
        :loading="item.loading"
        :options="item.options"
        type="object"
        @change="
          e => {
            item.name !== 'unit' ? $emit('update-schema', e.value) : ''
          }
        "
        @close="e => $emit('close-unit', e)"
        @open="getOptions(item, idx, $event)"
      />
      <ZemMultiSelect
        v-else-if="item.type === 'groups'"
        v-model="item.data"
        :loading="item.loading"
        :options="item.options"
        type="object"
        @change="e => $emit('update-schema', e.value)"
      />
      <div v-else-if="item.type === 'radio'" class="d-flex align-center" style="height: 26px">
        <ZemRadioButton
          v-for="(radio, radioIndex) in item.options"
          :key="radioIndex"
          v-model="item.data.value"
          :disabled="!editProduct && !newProduct"
          :label="radio.title"
          :value="radio.value"
          class="mr-3 mb-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import {options} from '@/mixins/options'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import {logger} from '@/assets/scripts/scripts'

export default {
  mixins: [options],
  components: {
    ZemRadioButton,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    editProduct: {
      type: Boolean,
      default: false,
    },
    newProduct: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {}
  },
  methods: {
    logger,
    values(item) {
      if (item.type === 'list') {
        return item.data.title
      } else if (item.type === 'groups') {
        let valuesToString = ''
        item.data.forEach((el, index, arr) => {
          valuesToString += el.title
          if (arr.length !== index + 1) {
            valuesToString += ', '
          }
        })
        return valuesToString
      } else if (item['input_name'] === 'bundle') {
        return item.data.title
      } else {
        return item.data
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-dropdown {
  margin-top: 0;
}
</style>

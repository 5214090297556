var render = function render(){var _vm=this,_c=_vm._self._c;return _c('zem-card',{staticClass:"product-header"},[_c('div',[(_vm.product || _vm.creating)?[_c('zem-card-title',[_vm._v(" "+_vm._s(_vm.creating ? _vm.$t('New product') : _vm.product.title)+" ")]),_c('div',{staticClass:"block-id"},[_c('span',{staticClass:"block-id__label"}),_vm._v(" ID "+_vm._s(_vm.product.number)+" ")])]:(_vm.productData)?[_c('zem-card-title',[_vm._v(" "+_vm._s(_vm.$t('Subgroup'))+": "+_vm._s(_vm.productData.title))]),(!_vm.creating)?_c('div',{staticClass:"block-id"},[_c('span',{staticClass:"block-id__label"}),_vm._v(" ID #"+_vm._s(_vm.productData.real_id)+" ")]):_vm._e()]:(_vm.product)?[_c('zem-card-title',[_vm._v(_vm._s(_vm.product.title.replaceAll('-', ' ')))]),_c('div',{staticClass:"block-id"},[_c('span',{staticClass:"block-id__label"}),_vm._v(" ID "+_vm._s(_vm.product.number)+" ")])]:_vm._e(),(_vm.product)?_c('div',{staticClass:"block-id"},[_c('span',{staticClass:"block-id__label second"}),_vm._v(" ID "+_vm._s(_vm.product.id)+" ")]):_vm._e()],2),_c('div',{staticClass:"tw-flex tw-gap-x-2"},[((!_vm.productData || _vm.creating) && !_vm.loading)?[(_vm.$can('edit', 'directory-item-update'))?[_c('zem-link',{staticClass:"md:tw-hidden tw-w-5",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"alt":"close","src":require("@/assets/icons/close.svg")}})]),_c('zem-link',{staticClass:"mr-2 mobile-d-none",on:{"click":() => {
              _vm.$emit('close')
            }}},[_vm._v(" Отменить ")]),_c('zem-button',{on:{"click":() => {
              _vm.$emit(_vm.creating ? 'create' : 'update')
            }}},[_vm._v(" Сохранить ")])]:_vm._e()]:_vm._e(),(_vm.loading)?_c('zem-button',{attrs:{"disabled":""}},[_c('zem-preloader',{attrs:{"color":"light"}})],1):_vm._e(),(_vm.productData)?_c('font-ai',{staticClass:"block-id__delete ml-2",attrs:{"icon":"fa-solid fa-xmark"},on:{"click":() => {
          _vm.$emit('close')
        }}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }